@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'TrapBlack';
    src: url('./font/Trap-Black.otf') format('opentype');
}
@font-face {
    font-family: 'TrapRegular';
    src: url('./font/Trap-Regular.otf') format('opentype');
}
@font-face {
    font-family: 'TrapBold';
    src: url('./font/Trap-Bold.otf') format('opentype');
}
@font-face {
    font-family: 'TrapSemi';
    src: url('./font/Trap-SemiBold.otf') format('opentype');
}
@font-face {
    font-family: 'TrapLight';
    src: url('./font/Trap-Light.otf') format('opentype');
}

.light {
    font-family: 'TrapLight';
}
.semi {
    font-family: 'TrapSemi';
}
.regular {
    font-family: 'TrapRegular';
}
.bold{
    font-family: 'TrapBold';
}
.light{
    font-family: 'TrapLight';
}
.black{
    font-family: 'TrapBlack';
}




body{
    background-color: #F5F5F7;
}

/* MARQUEE */
.marquee {
    position: relative;
    width: 100vw;
    max-width: 100%;
    height: 206px;
    overflow-x: hidden;
  }
  
  .track {
    position: absolute;
    white-space: nowrap;
  }
  
  .track > h1 {
    font-family: Antonio;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #f4955c;
    text-transform: uppercase;
  }